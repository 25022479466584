// utils
import { lazy } from "react";

// components
import Title from "@components/Title";
import TermsOfServiceContent from "@layout/terms-of-service";

const TermsOfService = () => {
  return (
    <>
      <Title title="Terms Of Service" />
      <main>
        <div className="section">
          <div className="container">
            <TermsOfServiceContent />
          </div>
        </div>
      </main>
    </>
  );
};

export default TermsOfService;
