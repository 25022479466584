// components
import Title from "@components/Title";
import PageHeader from "@components/PageHeader";
import JoinUsForm from "@layout/joinus/JoinUsForm";

const JoinUs = () => {
  return (
    <>
      <Title title="Join Us" />
      <PageHeader title="Join Us" />
      <main>
        <section>
          <div className="container">
            <JoinUsForm />
          </div>
        </section>
      </main>
    </>
  );
};

export default JoinUs;
