// GA
import ReactGA from "react-ga4";

// styling
import "./style.scss";

// libs styles
import "swiper/css";
import "swiper/css/effect-fade";
import "react-toastify/dist/ReactToastify.css";

// utils
import { lazy, Suspense } from "react";
import { preventDefault } from "@utils/helpers";

// hooks
import { useEffect } from "react";

// context
import { SidebarContextAPI } from "@contexts/sidebarContext";

// components
import LoadingScreen from "@components/LoadingScreen/LoadingScreen";
import AppLayout from "@components/AppLayout";
import { Routes, Route } from "react-router-dom";
import ScrollToTop from "@components/ScrollToTop";
import PrivacyPolicy from "@pages/PrivacyPolicy";
import TermsOfService from "@pages/TermsOfService";
import JoinUs from "@pages/JoinUs";

// pages
const Home = lazy(() => import("@pages/Home"));
const Contacts = lazy(() => import("@pages/Contacts"));
const PageNotFound = lazy(() => import("@pages/PageNotFound"));

const App = () => {
  useEffect(() => {
    preventDefault();
  }, []);

  const gaKey = process.env.REACT_APP_PUBLIC_GA;
  gaKey && ReactGA.initialize(gaKey);

  return (
    <SidebarContextAPI>
      <ScrollToTop />
      <AppLayout>
        <Suspense fallback={<LoadingScreen visible />}>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/join" element={<JoinUs />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/terms-of-service" element={<TermsOfService />} />
            <Route path="/contacts" element={<Contacts />} />
            <Route path="*" element={<PageNotFound />} />
          </Routes>
        </Suspense>
      </AppLayout>
    </SidebarContextAPI>
  );
};

export default App;
