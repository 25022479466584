// components
import Fade from "@mui/material/Fade";
import Lottie from "lottie-react";

// styling
import styles from "./style.module.scss";

// components
import AnimatedText from "react-animated-text-content-fork";

// utils
import { memo } from "react";

// assets
import preloader from "@assets/lottie/preloader.json";
import logo from "@assets/logo-white.webp";

// components
import Avatar from "@ui/Avatar";

const LoadingScreen = ({ visible }) => {
  return (
    <div className={styles.loading}>
      <Fade in={visible} timeout={1000}>
        <div className="loading-screen">
          <Lottie className="lottie" animationData={preloader} />
          <Avatar src={logo} size="sm" />
          <AnimatedText
            type="words"
            effect="verticalFadeIn"
            effectChange={0.2}
            effectDirection="up"
            duration={0.8}
            tag="h4"
            includeWhiteSpaces
          >
            © Probal Labs
          </AnimatedText>
        </div>
      </Fade>
    </div>
  );
};

export default memo(LoadingScreen);
