import React, { useState } from "react";
import { toast } from "react-toastify";
import styles from "./style.module.scss";
import GradientBtn from "@ui/GradientBtn";
import { Grid } from "@mui/material";

const JoinUsForm = () => {
  const [selectedCategory, setSelectedCategory] = useState("");
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");

  const handleSubmit = (e) => {
    if (selectedCategory === "") {
      e.preventDefault();
      toast.error("Please enter a category");
      return;
    }

    toast.success("Form data submitted successfully!");
  };

  const handleCategoryChange = (e) => {
    const newCategory = e.target.value;
    setSelectedCategory(newCategory);
  };
  const googleFormUrl = `https://docs.google.com/forms/d/e/1FAIpQLSfZp4CmLCEySPnj1CDqwrwKl2qcp54-SrH6i2vWvalNf_v-uw/formResponse`;

  return (
    <div className={`${styles.container} bg-secondary border-10`}>
      <div className={styles.main}>
        <h5 style={{ textAlign: "center", marginTop: "5%" }}>
          Be the First to Know
        </h5>
        <form
          className={styles.main_form}
          action={googleFormUrl} // Replace with your form ID
          target="_blank"
          onSubmit={handleSubmit}
        >
          <Grid sx={{ alignItems: "center" }}>
            <input
              style={{ marginBottom: "20px", width: "50vw" }}
              className={`field field--outline`}
              type="text"
              placeholder="Full name"
              name="entry.110051809" // Replace with your form entry number
              value={fullName}
              onChange={(e) => setFullName(e.target.value)}
              required
            />
            <input
              style={{ width: "50vw" }}
              className={`field field--outline`}
              type="text"
              placeholder="Email"
              name="entry.1120964992" // Replace with your form entry number
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              pattern="^\S+@\S+$"
            />
          </Grid>
          <Grid sx={{ alignItems: "center" }}>
            <select
              style={{
                width: "50vw",
                border: "2px solid #cccff11",
                backgroundColor: "transparent",
                marginBottom: "20px",
                fontSize: "14px",
              }}
              className={`field field--outline`}
              name="entry.1461854030" // Replace with your form entry number
              onChange={handleCategoryChange}
              value={selectedCategory}
              required
            >
              <option value="">Please choose a category</option>
              <option value="artist">Artist</option>
              <option value="investor">Investor</option>
              <option value="Movie Producer">Movie Producer</option>
              <option value="interested">Just Interested</option>
            </select>
          </Grid>
          <button style={{ marginBottom: "5%" }} type="submit">
            <GradientBtn className={styles.btn}>Submit</GradientBtn>
          </button>
        </form>
      </div>
    </div>
  );
};

export default JoinUsForm;
