// utils
import loadable from "@loadable/component";

// components
const Header = loadable(() => import("@components/Header"));
const Footer = loadable(() => import("@components/Footer"));

const AppLayout = ({ children }) => {
  return (
    <div>
      <Header />
      {children}
      <Footer />
    </div>
  );
};

export default AppLayout;
